<div *ngIf="currentMarket !== null">
    <div class="row">
        <div class="col-8 mt-4 mb-4">
            <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" [(ngModel)]="onlyManualPrices"
                    (ngModelChange)="onOnlyManualPricesChanges()" id="customSwitch2">
                <label class="custom-control-label" for="customSwitch2">Nur Richtpreise anzeigen</label>
            </div>
        </div>
        <div class="col-4 mt-4 mb-4">
            <svg-icon (click)="reload()" class="nav-icon refresh-icon" src="assets/refresh-24px.svg"></svg-icon>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <ng-container *ngFor="let player of playersToShow">
                <app-player-item [player]="player" [printMode]="false" [withoutApi]="false" [isMarketOverview]="true"
                    (loadDetails)="onLoadAllDetailsForPlayer(player)">
                </app-player-item>
            </ng-container>
        </div>

    </div>

</div>