<div class="row card p-3 mt-2" *ngIf="isAdminMode">
    <h4>Kickbase Login</h4>
    <div class="col-12">
        <!-- <form> -->
        <div class="form-group">
            <label for="exampleInputEmail1">Email address</label>
            <input type="email" [(ngModel)]="username" class="form-control" id="exampleInputEmail1"
                aria-describedby="emailHelp" placeholder="Enter email">
        </div>
        <div class="form-group">
            <label for="exampleInputPassword1">Password</label>
            <input type="password" [(ngModel)]="password" class="form-control" id="exampleInputPassword1"
                placeholder="Password">
        </div>
        <button (click)="loginPrivate()" type="submit" class="btn btn-primary btn-block">Login</button>
        <!-- </form> -->
    </div>
</div>
<div class="row card p-3 mt-4">
    <h4>Ligainsider Anbindung</h4>
    <div class="col-12 text-center">

        <div class="hcenter">
            <img class="start-picture" src="assets/favicon/android-icon-144x144.png" />
        </div>
    </div>
    <div class="col-12 text-center mt-2">
        <button (click)="loginWithoutAPI.emit()" type="submit" class="btn btn-primary btn-block">Starten</button>
    </div>
</div>